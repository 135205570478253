<template>
    <section class="pt-8">
        <div class="container full-height p-4">
            <login-heading :back-btn="true" heading="PAN/FORM-16 Information" size="lg">
                <template #prepend>
                    <span class="text-2 font-inter-bold fs--1 mb-4">REGISTRAION 3/4</span>
                </template>
            </login-heading>
            <div>
                <tabs color="primary" class="c-auth-tabs">
                    <tab title="Pan Details">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-4">
                                <validated-input label="PAN Number" class="c-input mb-5"></validated-input>
                                <btn block text="Skip for Now" color="secondary" design="basic-b" size="md"
                                     class="border-r--2 text-white py-3"/>
                                <btn block text="Next" @click="$router.push({name:'RegisterKycDocuments'})" class="mt-4 border-r--2 auth-button py-3"></btn>
                            </div>
                        </div>
                    </tab>
                    <tab title="Form-16">
                        Tab 2
                    </tab>
                </tabs>
            </div>
        </div>
    </section>
</template>

<script>
import LoginHeading from '@components/login/LoginHeading';

export default {
    name: 'RegisterPanForm',
    components: { LoginHeading }
};
</script>

<style scoped>

</style>
